<template>
    <div class="col" v-if="localdossier">
        <div class="bg-white" :class="{'py-4': !canEdit, 'p-4': canEdit}">
            <section id="settlement-new" :class="{'p-0': !canEdit}">
                <h6 :class="{'fs-5': !canEdit}">{{ canEdit ? ('Afhandeling aanvraag ' + selectedQuote.provider) : 'De actuele status van uw aanvraag'}}</h6>
                <div :class="{'d-flex justify-content-between': !canEdit, 'd-none': canEdit}">
                    <div class="w-75 fw-bold" style="padding-left: 2.4rem">Status van uw aanvraag bij {{selectedQuote.provider}}</div>
                    <div :class="'circle btn-sm px-4 py-1 fw-bold text-light ' + localdossier.bg" style="translate: 0px -3px">
                        {{localdossier.label}}
                    </div>
                </div>
                <div class="row" v-if="canEdit">
                    <div class="col-auto"> <i class="fa fa-check text-success"></i> </div>
                    <div :class="{'col-9': canEdit, 'col-auto pe-0': !canEdit}">{{ setStepText(1) }}</div>
                    <div class="col-auto"> {{dateOrNow(localdossier.dossier_accepted_date)}} </div>
                </div>
                <div class="my-2" v-if="canEdit && settlementStep == 0">
                    <button type="button" class="btn btn-success" @click="readySendSettlement">Aanvraag versturen</button>
                </div>
                <div class="row">
                    <div class="col-auto"> <i :class="{'fa fa-check text-success': settlementStep != 0, 'fa fa-close text-danger': settlementStep == 0 && canEdit}"></i> </div>
                    <div :class="{'col-9': canEdit, 'col-auto pe-0': !canEdit, 'muted': settlementStep == 0, 'fw-bold': !canEdit && settlementStep == 1}">{{ setStepText(2) }}</div>
                    <div class="col-auto" v-if="settlementStep != 0"> {{dateOrNow(localdossier.handling_quote_sent_date)}} </div>
                </div>
                <template v-for="reject, rindex in localdossier.rejections" :key="rindex">
                    <div class="row">
                        <div class="col-auto"> <i class="fa fa-close text-danger"></i> </div>
                        <div :class="{'col-9': canEdit, 'col-auto pe-0': !canEdit}">Aanvraag {{selectedQuote.provider}} is afgekeurd</div>
                        <div class="col-auto"> {{formatDate(reject.date, 'shortDate')}} </div>
                    </div>
                    <div class="fst-italic ps-4p5">&ldquo;{{ reject.reason }}&rdquo;</div>
                </template>
                <div class="my-2" v-if="canEdit && settlementStep == 1">
                    <button type="button" class="btn btn-success" @click="approveSettlement">Aanvraag goedgekeurd</button>
                    <button type="button" class="btn btn-danger ms-2" @click="rejectSettlement">Aanvraag afgekeurd</button>
                </div>
                <div class="row">
                    <div class="col-auto"> <i :class="{'fa fa-check text-success': settlementStep > 1, 'fa fa-close text-danger': settlementStep < 2 && canEdit}"></i> </div>
                    <div :class="{'col-9': canEdit, 'col-auto pe-0': !canEdit, 'muted': settlementStep < 2, 'fw-bold': !canEdit && settlementStep == 2}">{{ setStepText(3) }}</div>
                    <!-- eslint-disable-next-line -->
                    <div class="col-auto"> {{settlementStep < 2 ? '' : dateOrNow(localdossier.handling_quote_accepted_date)}} </div>
                    <i v-if="canEdit && settlementStep > 1" class="col-auto fa fa-xs fa-pencil text-muted reset-icon" @click="reset(1)"> </i>
                </div>
                <div class="my-2" v-if="canEdit && settlementStep == 2">
                    <button type="button" class="btn btn-primary" @click="settlementIsReceived" :disabled="!localdossier.canCompleteBuysum">Volledige koopsom is ontvangen</button>
                </div>
                <template v-if="!canEdit"> <div v-for="fund in dossier.pension_fund_history" :key="fund.id" :id="'pre-settlement-'+fund.id" class="row">
                    <div class="col-auto"> <i :class="{'fa fa-check text-success': (fund.step > 3 && settlementStep > 1), 'fa fa-close text-danger': (fund.step < 4 || settlementStep < 2) && canEdit}"></i> </div>
                    <div :class="{'col-9': canEdit, 'col-auto pe-0': !canEdit, 'muted': fund.step < 4 || settlementStep < 2, 'fw-bold': !canEdit && settlementStep == 3}">
                        {{selectedQuote.provider}} bevestigt de ontvangst van uw koopsom vanuit {{setFundName(fund)}}
                    </div>
                    <!-- eslint-disable-next-line -->
                    <div class="col-auto"> {{(fund.step < 4 || settlementStep == 1) ? '' : dateOrNow(fund.handling_fund_received_date)}} </div>
                </div> </template>
                <div class="row">
                    <div class="col-auto"> <i :class="{'fa fa-check text-success': settlementStep > 2, 'fa fa-close text-danger': settlementStep < 3 && canEdit}"></i> </div>
                    <div :class="{'col-9': canEdit, 'col-auto pe-0': !canEdit, 'muted': settlementStep < 3, 'fw-bold': !canEdit && settlementStep == 4}">{{ setStepText(4) }}</div>
                    <!-- eslint-disable-next-line -->
                    <div class="col-auto"> {{settlementStep < 3 ? '' : dateOrNow(localdossier.handling_funds_received_date)}} </div>
                    <i v-if="canEdit && settlementStep > 2" class="col-auto fa fa-xs fa-pencil text-muted reset-icon" @click="reset(2)"> </i>
                </div>
                <div v-if="localdossier.handling_funds_received_type && settlementStep >= 3" class="ps-4p5">
                    (Polis opmaak binnen 10-15 werkdagen na {{localdossier.handling_funds_received_type == 'na_ingangsdatum_polis' ? 'ingangsdatum polis' : 'deze bevestiging'}})
                </div>
                <div class="my-2" v-if="canEdit && settlementStep == 3">
                    <button type="button" class="btn btn-primary" @click="settlementCanHavePolis">Polis uploaden en informeren</button>
                </div>
                <div class="row">
                    <div class="col-auto"> <i :class="{'fa fa-check text-success': settlementStep > 3, 'fa fa-close text-danger': settlementStep < 4 && canEdit}"></i> </div>
                    <div :class="{'col-9': canEdit, 'col-auto pe-0': !canEdit, 'muted': settlementStep < 4, 'fw-bold': !canEdit && settlementStep == 5}">{{ setStepText(5) }}</div>
                    <!-- eslint-disable-next-line -->
                    <div class="col-auto"> {{settlementStep < 4 ? '' : dateOrNow(localdossier.handling_fund_transferred_date)}} </div>
                    <i v-if="canEdit && settlementStep > 3" class="col-auto fa fa-xs fa-pencil text-muted reset-icon" @click="reset(3)"> </i>
                </div>
                <div v-if="localdossier.handling_fund_transferred_type && settlementStep >= 4" class="ps-4p5">
                    (Koopsom is {{localdossier.handling_fund_transferred_type == 'gelijk' ? 'zoals verwacht' : ((localdossier.handling_fund_transferred_type == 'hoger' ? 'hoger' : 'lager') + ' dan verwacht')}})
                </div>
            </section>
            <hr v-show="!canEdit" class="my-4" />
            <h6 class="mb-0 fs-5" v-show="!canEdit">De actuele status van koopsommen</h6>
            <section v-for="fund, fundindex in dossier.pension_fund_history" :key="fund.id" :id="'settlement-'+fund.id" :class="{'px-0 py-2': !canEdit}">
                <hr v-show="canEdit || fundindex != 0" class="my-4" />
                <h6 v-show="canEdit">Afhandeling uitkeringsverzoek {{setFundName(fund)}}</h6>
                <div :class="{'d-flex justify-content-between': !canEdit, 'd-none': canEdit}">
                    <div class="w-75 fw-bold" style="padding-left: 2.4rem">Status van uw koopsom afkomstig van {{setFundName(fund)}}</div>
                    <div :class="'circle btn-sm px-4 py-1 fw-bold text-light ' + fund.bg" style="translate: 0px -3px">
                        {{fund.label}}
                    </div>
                </div>
                <div class="row" v-if="canEdit">
                    <div class="col-auto"> <i class="fa fa-check text-success"></i> </div>
                    <div :class="{'col-9': canEdit, 'col-auto pe-0': !canEdit}">{{ setFundStepText(fund, 1) }}</div>
                    <div class="col-auto"> {{dateOrNow(localdossier.dossier_accepted_date)}} </div>
                </div>
                <div class="row">
                    <div class="col-auto"> <i :class="{'fa fa-check text-success': fund.step != 0, 'fa fa-close text-danger': fund.step == 0 && canEdit}"></i> </div>
                    <div :class="{'col-9': canEdit, 'col-auto pe-0': !canEdit, 'muted': fund.step == 0, 'fw-bold': !canEdit && fund.step == 1}">{{ setFundStepText(fund, 2) }}</div>
                    <div class="col-auto"> {{fund.step == 0 ? "" : dateOrNow(fund.handling_benefit_request_sent_date)}} </div>
                </div>
                <div class="my-2" v-if="canEdit && fund.step == 0">
                    <button type="button" class="btn btn-success" @click="readySendFund(fund)">Verzoek tot overmaken versturen</button>
                </div>
                <template v-for="reject, rindex in fund.rejections" :key="rindex">
                    <div class="row">
                        <div class="col-auto"> <i class="fa fa-close text-danger"></i> </div>
                        <div :class="{'col-9': canEdit, 'col-auto pe-0': !canEdit}">Verzoek tot overmaken {{setFundName(fund)}} is afgekeurd</div>
                        <div class="col-auto"> {{formatDate(reject.date, 'shortDate')}} </div>
                    </div>
                    <div class="fst-italic ps-4p5">&ldquo;{{ reject.reason }}&rdquo;</div>
                </template>
                <div class="my-2" v-if="canEdit && fund.step == 1">
                    <button type="button" class="btn btn-success" @click="approveUV(fund)">Verzoek tot overmaken goedgekeurd</button>
                    <button type="button" class="btn btn-danger ms-2" @click="rejectUV(fund)">Verzoek tot overmaken afgekeurd</button>
                </div>
                <div class="row">
                    <div class="col-auto"> <i :class="{'fa fa-check text-success': fund.step > 1, 'fa fa-close text-danger': fund.step < 2 && canEdit}"></i> </div>
                    <div :class="{'col-9': canEdit, 'col-auto pe-0': !canEdit, 'muted': fund.step < 2, 'fw-bold': !canEdit && fund.step == 2}">{{ setFundStepText(fund, 3) }}</div>
                    <!-- eslint-disable-next-line -->
                    <div class="col-auto"> {{fund.step < 2 ? '' : dateOrNow(fund.handling_benefit_request_accepted_date)}} </div>
                    <i v-if="canEdit && fund.step > 1" class="col-auto fa fa-xs fa-pencil text-muted reset-icon" @click="reset(1, fund)"> </i>
                </div>
                <div class="my-2" :class="{'d-flex': fund.step == 2, 'd-none': fund.step != 2}" v-if="canEdit">
                    <button type="button" class="btn btn-primary col-5" @click.prevent="informUV(fund)" :disabled="!fund.handling_value_transfer_expire_date">Informeer cliënt waardeoverdracht</button>
                    <input type="date" class="form-control ms-3" style="width: 20%" v-model="fund.handling_value_transfer_expire_date" />
                </div>
                <div v-else-if="fund.step > 2 && fund.handling_value_transfer_expire_date" class="ps-4p5">
                    (Expiratiedatum waardeoverdracht: {{formatDate(fund.handling_value_transfer_expire_date, 'shortDate')}})
                </div>
                <div class="row" v-if="canEdit">
                    <div class="col-auto"> <i :class="{'fa fa-check text-success': fund.step > 2, 'fa fa-close text-danger': fund.step < 3 && canEdit}"></i> </div>
                    <div class="col-9" :class="{'muted': fund.step < 3}">Cliënt geïnformeerd over waardeoverdracht {{fund.handling_value_transfer_expire_date ? (' (expiratiedatum ' + formatDate(fund.handling_value_transfer_expire_date, 'shortDate') + ')') : ''}}</div>
                    <!-- eslint-disable-next-line -->
                    <div class="col-auto"> {{fund.step < 3 ? '' : dateOrNow(fund.handling_fund_transferred_date)}} </div>
                    <i v-if="canEdit && fund.step > 2" class="col-auto fa fa-xs fa-pencil text-muted reset-icon" @click="reset(2, fund)"> </i>
                </div>
                <template v-for="reject, rindex in fund.notyets" :key="rindex">
                    <div class="row" v-if="fund.step > 2 && reject.date >= fund.handling_fund_transferred_date">
                        <div class="col-auto"> <i class="fa fa-close text-danger"></i> </div>
                        <div :class="{'col-9': canEdit, 'col-auto pe-0': !canEdit}">{{ setFundStepText(fund, 4) }}</div>
                        <div class="col-auto"> {{formatDate(reject.date, 'shortDate')}} </div>
                    </div>
                    <div v-show="reject.reason && reject.reason != '-'" class="fst-italic ps-4p5">&ldquo;{{ reject.reason }}&rdquo;</div>
                </template>
                <div class="my-2" v-if="canEdit && fund.step == 3">
                    <button type="button" class="btn btn-success" @click="buysumUV(fund)">Koopsom is overgemaakt</button>
                    <button type="button" class="btn btn-danger ms-2" @click="noBuysumUV(fund)">Koopsom nog niet overgemaakt</button>
                </div>
                <div class="row">
                    <div class="col-auto"> <i :class="{'fa fa-check text-success': fund.step > 3, 'fa fa-close text-danger': fund.step < 4 && canEdit}"></i> </div>
                    <div :class="{'col-9': canEdit, 'col-auto pe-0': !canEdit, 'muted': fund.step < 4, 'fw-bold': !canEdit && fund.step == 4}">{{ setFundStepText(fund, 5) }}</div>
                    <!-- eslint-disable-next-line -->
                    <div class="col-auto"> {{fund.step < 4 ? '' : dateOrNow(fund.handling_fund_received_date)}} </div>
                    <i v-if="canEdit && fund.step > 3" class="col-auto fa fa-xs fa-pencil text-muted reset-icon" @click="reset(3, fund)"> </i>
                </div>
            </section>
        </div>
    </div>

    <div class="modal" :class="{'show': popup.active}" id="settlementModal" tabindex="-1" aria-labelledby="setmodlabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-l">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="setmodlabel">{{ popup.title }}</h5>
                <button type="button" class="btn-close" @click.prevent="dismissModal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p> {{popup.body}} </p>

                <template v-if="((popup.step == 1 && popup.status == 2) || (popup.step == 11 && popup.status == 2))">
                    <select v-model="popup.input" class="form-select w-100" @change="setExtraInfo(popup)">
                        <option :value="null" selected>Kies een reden...</option>
                        <option value="Handtekening komt niet overeen met identiteitsbewijs">Handtekening komt niet overeen met identiteitsbewijs</option>
                        <option value="Polisnummer is niet juist">Polisnummer is niet juist</option>
                        <option v-if="popup.step == 11" value="Nog niet uit dienst gemeld">Nog niet uit dienst gemeld</option>
                        <option value="Anders">Anders</option>
                    </select>
                </template>
                
                <template v-if="((popup.step == 1 && popup.status == 2) || (popup.step == 11 && popup.status == 2))">
                    <div class="pt-2">Toelichting (optioneel)</div>
                    <textarea v-model="popup.extra_info" class="form-control w-100" rows="5"></textarea>
                </template>
                <template v-else-if="(popup.step == 13 && popup.status == 1)">
                    <input type="checkbox" id="cdz-1" class="form-check-input me-2" v-model="popupCDZ1" @change="setDenyText(1)" /> 
                    <label for="cdz-1">Binnen 5 werkdagen overgedragen</label>
                    <div v-if="popupCDZ1">
                        <input type="checkbox" class="form-check-input me-2" id="cdz-2" v-model="popupCDZ2" @change="setDenyText(2)" /> 
                        <label for="cdz-2">Toelichting cliënt niet zelf hoeft over te dragen</label>
                    </div>
                    <textarea v-model="popup.input" class="form-control w-100" rows="5"></textarea>
                </template>
                <template v-else-if="popup.step == 2">
                    <div> <input type="radio" id="rip-1" name="popupinput" value="na_vandaag" v-model="popup.input" />&nbsp;<label for="rip-1">Polis opmaak binnen 10-15 werkdagen na vandaag</label> </div>
                    <div> <input type="radio" id="rip-2" name="popupinput" value="na_ingangsdatum_polis" v-model="popup.input" />&nbsp;<label for="rip-2">Polis opmaak binnen 10-15 werkdagen na ingangsdatum polis</label> </div>
                </template>
                <template v-else-if="popup.step == 3">
                    <div> <input type="radio" id="rip-1" name="popupinput" value="gelijk" v-model="popup.input" />&nbsp;<label for="rip-1">Koopsom is gelijkgebleven</label> </div>
                    <div> <input type="radio" id="rip-2" name="popupinput" value="hoger" v-model="popup.input" />&nbsp;<label for="rip-2">Koopsom is hoger</label> </div>
                    <div> <input type="radio" id="rip-3" name="popupinput" value="lager" v-model="popup.input" />&nbsp;<label for="rip-3">Koopsom is lager</label> </div>
                    <br />
                    <UploadModule :dossier="dossier" type="polis_upload" owner="polis_upload" ref="polis_upload" maxfiles=1 v-if="localdossier.lijfrente_type != 'bancair'" />
                    <div class="invalid-feedback"> </div>
                </template>
                <div class="d-flex" v-else-if="popup.step == 13 && popup.status == 2">
                    <div class="input-group w-50">
                        <span class="input-group-text">€</span>
                        <input v-model="popup.input" name="price" class="form-control form-control-sm"/>
                    </div>
                    <div class="input-group w-50 ps-4">
                        <span class="input-group-text"> <i class="fal fa-xs fa-calendar"></i> </span>
                        <input v-model="popup.input2" name="date" type="date" class="form-control form-control-sm"/>
                    </div>
                </div>
                
            </div>
            <div class="modal-footer" v-if="popup.status == 1 && canEdit">
                <button type="button" class="btn btn-danger" id="closemodal1" @click.prevent="dismissModal">Nee</button>
                <button type="button" class="btn btn-success" id="closemodal2" @click.prevent="completeStep">Ja</button>
            </div>
            <div class="modal-footer" v-else-if="canEdit">
                <button type="button" class="btn btn-primary" id="closemodal3" @click.prevent="completeStep" :disabled="checkDisabled()">Verzenden</button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
    import dossierservice from '@/services/admin/DossierService'
    import UploadModule from '@/components/dossier/UploadModule'

    export default {
        name: 'Quotes',
        components: {
            UploadModule
        },
        props: {
            dossier: {},
            selectedQuote: {},
            canEdit: null
        },
        data() {
            return {
                companies: [],
                localdossier: null,
                settlementStep: 1,
                popup: {
                    active: false,
                    title: "",
                    body: "",
                    status: 1,
                    step: 0,
                    input: "",
                    file: null
                },
                activeFund: null,
                prevDossier: null,
                quote: {
                    "id": null
                },
                popupCDZ1: false,
                popupCDZ2: false
            }
        },
        computed: {
            isNotPension(){
                return this.dossier.pension_fund_history[0] && this.dossier.pension_fund_history[0].origin != "Pensioen";
            },
            filteredQuotes(){
                return (this.localdossier.lijfrente_type && this.isNotPension) ? this.localdossier.quotes.filter(q => (q.lijfrente_type == this.localdossier.lijfrente_type || !q.lijfrente_type)) : this.localdossier.quotes;
            }
        },
        methods: {
            setStepText(id){
                if(id == 1)
                    return `Aanvraag ${this.selectedQuote.provider} is ondertekend`;
                if(id == 2)
                    return `Aanvraag ${this.selectedQuote.provider} is ${this.canEdit ? 'verstuurd' : 'ingediend'}`;
                if(id == 3)
                    return `De aanvraag is goedgekeurd door ${this.selectedQuote.provider}`;
                if(id == 4)
                    return this.canEdit ? `Volledige koopsom is ontvangen bij ${this.selectedQuote.provider}` : `${this.selectedQuote.provider} bevestigt dat de volledige koopsom is ontvangen`;
                if(id == 5 && this.canEdit){
                    if(this.selectedQuote.provider == "Allianz")
                        return `Bancair bemiddeling ${this.selectedQuote.provider} afgerond`;
                    if(this.localdossier.lijfrente_type == "bancair")
                        return `Polis ${this.selectedQuote.provider} is opgesteld en beschikbaar in het ${this.selectedQuote.provider} portaal`;
                    return `Polis ${this.selectedQuote.provider} is opgesteld en geüpload`;
                }
                if(id == 5)
                    return `${this.selectedQuote.provider} maakt de polis op, deze kunt u downloaden in Mijn Apple Tree`;
            },
            setFundStepText(fund, id){
                const name = fund.pension_execution_company_name || fund.pension_company.name;
                if(id == 1)
                    return `Verzoek tot overmaken ${name} is ondertekend${this.canEdit ? '' : ' door u'}`;
                if(id == 2)
                    return `Verzoek tot overmaken ${name} is ${this.canEdit ? 'verstuurd' : 'ingediend'}`;
                if(id == 3)
                    return `Verzoek tot overmaken is goedgekeurd door ${name}`;
                if(id == 4)
                    return this.canEdit ? `Cliënt geïnformeerd dat koopsom ${name} nog niet is overgemaakt` : `Koopsom ${name} is nog niet overgemaakt`;
                if(id == 5 && this.canEdit)
                    return `Cliënt geïnformeerd dat koopsom${fund.handling_fund_transferred_amount ? (' van ' + this.formatCurrency(fund.handling_fund_transferred_amount)) : ''} is overgemaakt naar ${this.selectedQuote.provider}`;
                if(id == 5)
                    return `${name} bevestigt dat de koopsom${fund.handling_fund_transferred_amount ? (' van ' + this.formatCurrency(fund.handling_fund_transferred_amount)) : ''} is overgemaakt naar ${this.selectedQuote.provider}`;
            },
            dateOrNow(date){
                return this.formatDate(date ? date : new Date(), 'shortDate')
            },
            addSeparators(){
                this.localdossier.quotes.forEach(item => {
                    if(item.payment_low_special_offer && item.payment_low_special_offer != null){
                        item.payment_low_special_offer = new Intl.NumberFormat('en', {useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2}).format(item.payment_low_special_offer);
                    }
                    if(item.payment_special_offer && item.payment_special_offer != null){
                        item.payment_special_offer = new Intl.NumberFormat('en', {useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2}).format(item.payment_special_offer);
                    }
                });
            },
            changeSeparators(){
                this.localdossier.quotes.forEach(item => {
                    if(item.payment_low_special_offer && item.payment_low_special_offer != null){
                        item.payment_low_special_offer = String(item.payment_low_special_offer).replace('.', '');
                    }
                    if(item.payment_special_offer && item.payment_special_offer != null){
                        item.payment_special_offer = String(item.payment_special_offer).replace('.', '');
                    }
                });
            },
            resetPopup(resetInput = true){
                this.popup.active = true;
                this.popup.file = null;
                this.activeFund = null;
                if(resetInput) this.popup.input = null;
            },
            readySendSettlement(){
                this.popup = {
                    title: "Aanvraag versturen",
                    body: "Weet u zeker dat u de aanvraag wilt versturen?",
                    status: 1,
                    step: 0
                };
                this.resetPopup();
            },
            approveSettlement(){
                this.popup = {
                    title: "Aanvraag goedkeuren",
                    body: "Wilt u de klant informeren dat de aanvraag is goedgekeurd?",
                    status: 1,
                    step: 1
                };
                this.resetPopup();
            },
            rejectSettlement(){
                this.popup  = {
                    title: "Aanvraag afkeuren",
                    body: "Wat is de reden van het afkeuren?",
                    status: 2,
                    step: 1,
                    dismiss: true
                };
                this.resetPopup();
            },
            settlementIsReceived(){
                this.popup  = {
                    title: "Koopsom is ontvangen",
                    body: "Kies een optie:",
                    step: 2,
                    input: '1'
                };
                this.resetPopup(false);
            },
            settlementCanHavePolis(){
                this.popup  = {
                    title: "Polis uploaden",
                    body: "Kies een optie en upload de polis.",
                    step: 3
                };
                this.resetPopup();
            },
            readySendFund(fund){
                this.popup = {
                    title: "Verzoek tot overmaken versturen",
                    body: "Weet u zeker dat u het verzoek tot overmaken wilt versturen?",
                    status: 1,
                    step: 10
                };
                this.resetPopup();
                this.activeFund = fund;
            },
            approveUV(fund){
                this.popup = {
                    title: "Uitkeringsverzoek goedkeuren",
                    body: "Wilt u de klant informeren dat het uitkeringsverzoek is goedgekeurd?",
                    status: 1,
                    step: 11
                };
                this.resetPopup();
                this.activeFund = fund;
            },
            rejectUV(fund){
                this.popup  = {
                    title: "Uitkeringsverzoek afkeuren",
                    body: "Wat is de reden van het afkeuren?",
                    status: 2,
                    step: 11,
                    dismiss: true
                };
                this.resetPopup();
                this.activeFund = fund;
            },
            informUV(fund){
                this.popup  = {
                    title: "Cliënt informeren",
                    body: "Weet u zeker dat u de cliënt wil informeren over de waardeoverdracht?",
                    status: 1,
                    step: 12
                };
                this.resetPopup();
                this.activeFund = fund;
            },
            buysumUV(fund){
                this.popup  = {
                    title: "Cliënt informeren",
                    body: "Weet u zeker dat u de cliënt wil informeren? Voer dan hier de koopsom en datum in.",
                    status: 2,
                    step: 13,
                    input2: new Date().toISOString().substring(0, 10)
                };
                this.resetPopup();
                this.activeFund = fund;
            },
            noBuysumUV(fund){
                this.popup  = {
                    title: "Cliënt informeren",
                    body: "Wilt u de cliënt informeren dat de koopsom nog niet is overgemaakt? U kunt eventueel een toelichting geven.",
                    status: 1,
                    step: 13,
                    dismiss: true
                };
                this.resetPopup();
                this.activeFund = fund;
            },
            dismissModal(){
                this.popup.active = false;
            },
            completeStep(){
                const id = this.dossier.id;
                if(this.popup.extra_info){
                    if(this.popup.input == "Anders"){
                        this.popup.input = this.popup.extra_info;
                    }
                    else{
                        this.popup.input += ("\n" + this.popup.extra_info);
                    }
                }
                if(this.popup.step < 10){
                    if(this.popup.dismiss){
                        this.localdossier.rejections.push({'date': new Date(), 'reason': this.popup.input});
                        dossierservice.setRejection(this.popup, id).catch(this.toastError);
                    }
                    else{
                        this.settlementStep++;
                        dossierservice.setCompletionStep(this.popup, id).catch(this.toastError);
                    }
                }
                else if(this.popup.step >= 10 && !this.popup.dismiss){
                    this.activeFund.step++;
                    if(this.popup.step == 12){
                        this.popup.input = this.activeFund.handling_value_transfer_expire_date;
                    }
                    if(this.popup.step == 13){
                        this.popup.input = Number(this.popup.input)
                        this.activeFund.handling_fund_transferred_amount = this.popup.input;
                        this.activeFund.handling_fund_received_date = this.popup.input2;
                    }
                    let minFundStep = 5;
                    this.localdossier.pension_fund_history.forEach(fund => {
                        if(minFundStep > fund.step) minFundStep = fund.step;
                    });
                    this.localdossier.canCompleteBuysum = minFundStep > 3;
                    dossierservice.setFundCompletionStep(this.popup, id, this.activeFund.id).catch(this.toastError);
                }
                else if(this.popup.status == 2){
                    this.activeFund.rejections.push({'date': new Date(), 'reason': this.popup.input});
                    dossierservice.setFundRejection(this.popup, id, this.activeFund.id).catch(this.toastError);
                }
                else if(this.popup.status == 1){
                    this.activeFund.notyets.push({'date': new Date(), 'reason': this.popup.input});
                    dossierservice.setFundNotYet(this.popup, id, this.activeFund.id).catch(this.toastError);
                }
                this.dismissModal();
            },
            checkDisabled(){
                if(!this.popup.input) return true;
                if(this.popup.step == 3 && this.popup.file == null && this.localdossier.lijfrente_type != "bancair") return true;
                if(((this.popup.step == 1 && this.popup.status == 2) || (this.popup.step == 11 && this.popup.status == 2)) && this.popup.input == "Anders" && !this.popup.extra_info) return true;
                return false;
            },
            reset(step, fund = false){
                if(confirm("Weet je zeker dat je terug wil gaan naar deze stap?")){
                    if(fund){
                        if(step < 2) fund.handling_benefit_request_accepted_date = null;
                        if(step < 3) fund.handling_fund_transferred_date = null;
                        if(step < 4) fund.handling_fund_received_date = null;
                    }
                    else{
                        if(step < 2) this.localdossier.handling_quote_accepted_date = null;
                        if(step < 3) this.localdossier.handling_funds_received_date = null;
                        if(step < 4) this.localdossier.handling_fund_transferred_date = null;
                        this.setStepNumber();
                    }
                    this.setStepNumberFunds();
                    dossierservice.resetToStep(step, this.dossier.id, fund ? fund.id : null).catch(this.toastError);
                }
            },
            setFundName(fund){
                if(this.canEdit)
                    return fund.pension_company.id == 35 ? (fund.pension_execution_company_name + ' (Handmatig ingevuld)') : fund.pension_company.name;
                return fund.pension_execution_company_name || fund.pension_company.name;
            },
            setExtraInfo(popup){
                const name = popup.step > 10 ? this.activeFund.pension_execution_company_name || this.activeFund.pension_company.name : this.selectedQuote.provider;
                if(popup.input == "Nog niet uit dienst gemeld"){
                    popup.extra_info = `Bij ${name} is uw verzoek tot uitbetaling ontvangen. Zij geven echter aan dat u nog niet uit dienst bent gemeld door uw werkgever. Dit kan ervoor zorgen dat u nog post/e-mails ontvangt van ${name} met de informatie dat u een pensioen dient aan te kopen. Hier hoeft u dan niks mee te doen, aangezien dit automatische berichten zijn.
 
Het is belangrijk dat u tijdig uit dienst wordt gemeld door uw werkgever. Zou u ons willen laten weten wanneer dit zal gebeuren? Zodra u uit dienst bent gemeld zal de automatische post/e-mail omtrent het aankopen van uw pensioen stoppen.`;
                }
            },
            setDenyText(){
                this.popup.input = "";
                if(this.popupCDZ1){
                    this.popup.input = "De koopsom is nog niet overgedragen, maar de waarde wordt over 5 werkdagen wel overgedragen.\n";
                }
                if(this.popupCDZ1 && this.popupCDZ2){
                    const name = this.activeFund.pension_execution_company_name || this.activeFund.pension_company.name;
                    this.popup.input += `U ontvangt (of heeft ontvangen) een brief van ${name} waarin wordt aangegeven dat u deze dient door te sturen naar de ontvangende partij. Dit hoeft u niet te doen. U heeft ons ingeschakeld en dus verzorgen wij dit voor u.`;
                }
            },
            setStepNumber(){
                this.settlementStep = 0;
                if(this.localdossier.handling_quote_sent_date !== null) this.settlementStep = 1;
                if(this.localdossier.handling_quote_accepted_date !== null) this.settlementStep = 2;
                if(this.localdossier.handling_funds_received_date !== null) this.settlementStep = 3;
                if(this.localdossier.handling_fund_transferred_date !== null) this.settlementStep = 4;
                this.localdossier.label = "In behandeling";
                this.localdossier.bg = "bg-warning";
                if(this.settlementStep == 1 && this.localdossier.rejections.length > 0){
                    this.localdossier.label = "Afgekeurd";
                    this.localdossier.bg = "bg-danger";
                }
                if(this.settlementStep >= 4){
                    this.localdossier.label = "Afgerond";
                    this.localdossier.bg = "bg-success-alt";
                }
            }, 
            setStepNumberFunds(){
                let minFundStep = 5;
                this.localdossier.pension_fund_history.forEach(fund => {
                    fund.step = 0;
                    if(fund.handling_benefit_request_sent_date !== null) fund.step = 1;
                    if(fund.handling_benefit_request_accepted_date !== null) fund.step = 2;
                    if(fund.handling_fund_transferred_date !== null) fund.step = 3;
                    if(fund.handling_fund_received_date !== null) fund.step = 4;
                    if(minFundStep > fund.step) minFundStep = fund.step;
                    fund.label = "In behandeling";
                    fund.bg = "bg-warning";
                    fund.rejections = [];
                    fund.dossier_handlings.filter(h => h.handle_type == "handling_benefit_request_denied").forEach(h => {
                        fund.rejections.push({
                            "reason": h.reason,
                            "date": h.created_at
                        });
                    });
                    fund.notyets = [];
                    fund.dossier_handlings.filter(h => h.handle_type == "handling_fund_received_denied").forEach(h => {
                        fund.notyets.push({
                            "reason": h.reason,
                            "date": h.created_at
                        });
                    });
                    if(fund.step == 1 && fund.rejections.length > 0){
                        fund.label = "Afgekeurd";
                        fund.bg = "bg-danger";
                    }
                    if(fund.step >= 4){
                        fund.label = "Afgerond";
                        fund.bg = "bg-success-alt";
                    }
                });
                
                if(!this.canEdit){
                    let maxSettlementStep = 1;
                    if(this.settlementStep > 1 && minFundStep > 1){
                        maxSettlementStep = 2;
                        if(minFundStep > 2) maxSettlementStep = 3;
                        if(minFundStep > 3 && this.settlementStep > 2) maxSettlementStep = 4;
                        if(minFundStep > 3 && this.settlementStep > 3) maxSettlementStep = 5;
                    }
                    this.$parent.$parent.$refs.dossierprogress.maxSettlementStep = maxSettlementStep;
                }
                else{
                    this.localdossier.canCompleteBuysum = minFundStep > 3;
                }
            }
        },
        mounted() {
            this.localdossier = this.dossier;
            this.localdossier.rejections = [];
            this.localdossier.dossier_handlings.filter(h => h.handle_type == "handling_quote_denied").forEach(h => {
                this.localdossier.rejections.push({
                    "reason": h.reason,
                    "date": h.created_at
                });
            });
            this.setStepNumber();
            this.setStepNumberFunds();
            this.prevDossier = JSON.parse(JSON.stringify(this.dossier));
        }
    }
</script>
<style scoped>
    div.col-9{
        width: 80% !important
    }
    .modal.show{
        display: block !important;
        background-color: rgb(1,1,1,0.5)
    }
    /* Remove spinners */
    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
        appearance: textfield;
    }
    .col-auto.pe-0{
        width: 80.6% !important
    }
    section > .row > div:first-child{
        min-width: 38px
    }
    .muted{
        color: #bbb
    }
    .ps-4p5{
        padding-left: 2.4rem;
        padding-right: 18%;
        white-space: pre-wrap;
    }
    .reset-icon{
        padding-top: .7rem;
        padding-left: 0 !important;
        padding-right: 0 !important
    }
    .reset-icon:hover{
        cursor: pointer;
        color: #baa !important;
    }
</style>