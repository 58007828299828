import http from '@/http-common-contact'


const URL = '/api/dossier'

class DossierService {
  signDossier(dossierId, signature, isPartner, cropSignature) {
    return http.post(`${URL}/${dossierId}/sign`, {
      'signature': signature,
      'cropSignature': cropSignature,
      'partner': isPartner
    })
  }
  acceptDossier(dossierId) {
    return http.post(`${URL}/${dossierId}/accept-dossier`)
  }
  getDossiers(page, params) {
    const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
    return http.get(`${URL}?page=` + page + `&` + queryParams)
  }
  getDossier(dossierId) {
    return http.get(`${URL}/${dossierId}`)
  }
  generateDocument(dossierId, type, fundId = '') {
    return http.get(`${URL}/${dossierId}/download-document/${type}?fundId=${fundId}`, {
      responseType: 'blob'
    })
  }
  acceptQuote(dossierId, selectedQuote) {
    return http.post(`${URL}/${dossierId}/quotes/accept`, {
      'quote_id': selectedQuote
    })
  }
  acceptQuoteOffer(dossierId, selectedQuote) {
    return http.post(`${URL}/${dossierId}/quotes/accept/offer`, {
      'quote_id': selectedQuote
    })
  }
  downloadQuoteFile(id) {
    return http.get(`${URL}/quote/${id}/download-file`, {
      responseType: 'blob'
    })
  }
  updatePensionHistory(dossierId, data) {
    return http.post(`${URL}/${dossierId}/pension/update`, {
      'pensions': data
    })
  }
  updatePayroll(dossierId, data) {
    return http.post(`${URL}/${dossierId}/payroll/update`, data)
  }
  paySource(dossierId) {
    return http.post(`${URL}/${dossierId}/mollie/prepare`)
  }
  getPolisCompanies(is_pension, is_lijfrente) {
    return http.get(`${URL}/polis-companies?is_pension=${is_pension}&is_lijfrente=${is_lijfrente}`)
  }
  uploadFiles(dossierId, files, type) {
    return http.post(`${URL}/${dossierId}/documents/upload/${type}`, files)
  }
  deleteFile(dossierId, fileId) {
    return http.post(`${URL}/${dossierId}/documents/delete/${fileId}`)
  }
  downloadFile(dossierId, fileId) {
    return http.get(`${URL}/${dossierId}/file/${fileId}`, {
      responseType: 'blob'
    })
  }
  getDossierSteps() {
    return http.get(`${URL}/steps`)
  }
  updateStep(dossierId, stepId, stepNumber) {
    return http.put(`${URL}/${dossierId}/steps`, {
      "step_id": stepId,
      "step_number": stepNumber
    })
  }

}

export default new DossierService()