<template>
  <h5 v-html="title"></h5>
  <p v-html="description" class=""></p>
  <div class="dragdropupload" @dragover="dragover" @dragleave="dragleave" @drop="drop($event)" @click="chooseFilesOnClick">
        <input type="file" multiple :name="'fields[assetsFieldHandle_' + type + '][]'" :id="'assetsFieldHandle_' + type" class="input-drag-and-drop opacity-0 overflow-hidden absolute" 
               @change="onChange(type)" ref="file" accept=".pdf,.jpg,.jpeg,.png"  :disabled="disabled" />
        <label  v-if="!this.files.length" :for="'assetsFieldHandle_' + type" class="block cursor-pointer">
        <div v-html="uploadText ? uploadText : 'Sleep uw bestand of klik om te uploaden…'">
        </div>
            
        </label>
        <ul class="mt-4" v-if="this.files.length" v-cloak>
          <li class="text-sm p-1" v-for="file in files" v-bind:key="file">
              <img class="uploaded-file-preview" :src="readFile(file)" :alt="file.name" /> 
              <div>
                {{ file.name }} <br>
                <button class="ml-2 btn btn-secondary fileDeleteButton text-danger" type="button" :disabled="disabled" @click="remove(files.indexOf(file))" title="Remove file">Verwijder</button>
              </div>
          </li>
        </ul>
  </div>	
  <div v-if="this.owner == 'contact'">
    <ul>
      <template v-for="file in dossier.files" :key="file.id">
        <li id="document-upload-buttons" v-if="this.type == file.content_type">
          <a class="text-success" href="" @click.prevent="downloadDocument(file)"><i class="fa-regular fa-check"></i> {{file.name}}</a>
          <template v-if="!disabled"> - <a class="text-danger" href="" @click.prevent="deleteDocument(file)">Verwijder</a></template>
        </li>
      </template>
    </ul>
  </div>

</template>

<script>
import contactDossierservice from '@/services/contact/DossierService'
import adminDossierservice from '@/services/admin/DossierService'
import pdfImage from "@/assets/pdf.png"

export default {
  name: 'UploadModule',
  data(){
    return {
        files: [],
        pdfImage: pdfImage,
        fileUploadCount: 0
    }
  },
  emits: ["filesUploaded"],
  props: { 
    dossier: {},
    title: {},
    description: {},
    type: {},
    owner: {},
    maxfiles: {},
    uploadText: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async submitFiles () {
        let formData = new FormData();
        for( var i = 0; i < this.files.length; i++ ){
          let file = this.files[i]
          formData.append('files[' + i + ']', file)
        }

        if (this.files.length != 0) {
          if (this.owner == 'contact') {
            contactDossierservice.uploadFiles(this.dossier.id, formData, this.type).then(response => {
              this.fileUploadCount += this.files.length
              this.files = []
              this.$toast.success('Bestand is geüpload')
              this.$emit('filesUploaded')
              this.$parent.dossier.files = response.data.files
            }).catch(error => {
              this.$toast.error('Bestand is niet geüpload')
            })
          } else if (this.owner == 'admin') {
            adminDossierservice.uploadFiles(this.dossier.id, formData, this.type).then(response => {
              this.fileUploadCount += this.files.length
              this.$toast.success('Bestand is geüpload')
              this.files = []
              this.$emit('filesUploaded')
              this.hasUploadedFile = true
            }).catch(error => {
              this.$toast.error('Bestand is niet geüpload')
            })
          }
        }
    },
    handleFilesUpload(){
        this.files = this.$refs.files.files
    },
    onChange() {
      if(this.disabled) return;
      this.files.push(...this.$refs.file.files)
      if (this.owner == 'contact') {
        this.submitFiles()
      } else if (this.owner == 'polis_upload'){
        this.$parent.popup.file = this.$refs.file.files[0];
      }
    },
    reset() {
      this.files = []
    },
    remove(index) {
      this.files.splice(index, 1)
    },
    chooseFilesOnClick(e){
      if(e.target.classList.contains('dragdropupload')){
        e.target.firstElementChild.click();
      }
    },
    readFile(file){
      if (file.type == "application/pdf") {
        return this.pdfImage
      } else {
        return URL.createObjectURL(file)
      }
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100')
        event.currentTarget.classList.add('bg-green-300')
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    },
    drop(event) {
      event.preventDefault();
      if(!this.maxfiles || this.files < this.maxfiles)
      {
        this.$refs.file.files = event.dataTransfer.files
        this.onChange() // Trigger the onChange event manually
      }
      
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    },
    downloadDocument (file) {
      contactDossierservice.downloadFile(this.dossier.id, file.id).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file.name);
          document.body.appendChild(link);
          link.click();
      }).catch(error => {
          this.$toast.error('Kon bestand niet downloaden')
      });
    },
    deleteDocument (file) {
      this.fileUploadCount -= 1
      contactDossierservice.deleteFile(this.dossier.id, file.id).then(response => {
          this.$parent.dossier.files = response.data.files
          this.$toast.success('Bestand is verwijderd')
      }).catch(error => {
          this.$toast.error('Kon bestand niet verwijderen')
          this.fileUploadCount += 1
      });
    }    
  },
  mounted() {
    this.fileUploadCount = this.dossier.files.filter(file => file.content_type == this.type).length
  }
}
</script>
<style scoped>
.dragdropupload {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
}
.dragdropupload ul {
  list-style: none;
} 

#document-upload-buttons {
   list-style: none;
}

.dragdropupload ul li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dragdropupload ul li div {
  padding-left: 15px;
}

.dragdropupload ul li button {
  position: relative;
  left: 0px;
}
.file-drop-field{
  margin: auto;
  min-height: 200px;
  width: 70%;
  background-color: rgb(238, 238, 238);
}
.file-drop-field input{
  display: none;
}
[v-cloak] {
  display: none;
}
.uploaded-file-preview{
  height: 100px;
}
.fileDeleteButton {
  position: relative;
  top: 4px;
  left: 65px;
}
</style>